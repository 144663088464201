export const REFUND = 'REFUND';
export const INVOICE = 'INVOICE';
export const CHARGE = 'CHARGE';
export const PAYMENT = 'PAYMENT';
export const PARTIAL_REFUND = 'PARTIAL_REFUND';
export const CREDIT = 'CREDIT';
export const UNKNOWN = 'UNKNOWN';

export type TRANSACTION_TYPE =
  | typeof UNKNOWN
  | typeof CREDIT
  | typeof PAYMENT
  | typeof INVOICE
  | typeof REFUND;

import { DATE_FORMATS } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import { format } from '$gbusiness/helpers/date';
import { INVOICE_PAYMENTS } from '$fbusiness/enums/options/paymentType';
//import mockData from '$business/mocks/getInvoices.json';

import { currency } from '$gbusiness/helpers/util';
import intl from '$intl';
import { Edit } from '@mui/icons-material';
import { Flex } from '$gstyles/wrapper';
import { displayPaymentInfo } from './utils';

const derivePaymentType = (row) => {
  return (
    <div className={row.isRefund ? 'uppercase' : ''}>
      {!row.type
        ? ''
        : row?.amount >= 0
        ? intl('INPUT.OPTION.PAYMENT_TYPE.' + row.type)
        : row.isRc
        ? 'REFUND CREDIT'
        : intl('COMMON.DISCOUNT')}
    </div>
  );
};

export const INVOICE_PAYMENTS_TABLE_MIN = (onClickOriginal: any = null): Array<CellModel> => [
  {
    label: 'SCREEN.INVOICE_PAYMENT.COLS.PAYMENT_TYPE',
    value: 'amount',
    alwaysShow: true,
    component: (row) => derivePaymentType(row),
    className: 'main',
    width: 200,
  },
  {
    label: 'SCREEN.INVOICE_PAYMENT.COLS.DATE',
    value: 'paymentDate',
    component: (row) => <>{format(row.paymentDate, DATE_FORMATS.SLASH)}</>,
    alwaysShow: true,
    width: 200,
  },
  {
    label: 'SCREEN.INVOICE_PAYMENT.COLS.ORIGINAL',
    value: 'original',
    component: (row) => (
      <span
      // className={onClickOriginal && 'link'}
      // onClick={() => onClickOriginal && onClickOriginal(row)}
      >
        {row.originalAmount === null ? '' : currency(row.originalAmount)}
      </span>
    ),
    alwaysShow: true,
    width: 170,
  },
  {
    label: 'SCREEN.INVOICE_PAYMENT.COLS.AMOUNT',
    value: 'amount',
    component: (row) => (
      <span className={`${row.type === INVOICE_PAYMENTS.DISCOUNT && 'successHold outlined'}`}>
        {currency(row.amount * (row.isRefund ? 1 : -1))}
      </span>
    ),
    alwaysShow: true,
    width: 150,
  },
  {
    label: 'SCREEN.INVOICE_PAYMENT.COLS.INFO',
    value: 'amount',
    component: displayPaymentInfo,
    alwaysShow: true,
    width: 300,
  },
];

export const INNER_PAYMENTS_TABLE = (onEditPayment): Array<CellModel> => [
  ...INVOICE_PAYMENTS_TABLE_MIN(),
  {
    label: '',
    value: '',
    className: 'action',
    component: (row, actions, i) => (
      <div style={{ width: '100%' }}>
        {true && (
          <Flex justifyContent="flex-end">
            {row.id > 0 && row.type !== INVOICE_PAYMENTS.CREDITS && (
              <Edit onClick={() => onEditPayment(row, i)} className="pointer small" />
            )}
            {/* <Delete onClick={() => onDeletePayment(row, i)} className="pointer small" /> */}
          </Flex>
        )}
      </div>
    ),
    minWidth: 60,
    width: 60,
  },
];

import { TICKET_STATUS_OPTIONS } from '$fbusiness/enums/options/ticketStatus';
import { INPUT } from '$gbusiness/enums';
import { insertEmptyOption } from '$gbusiness/helpers/input';
import InputRowModel from '$gbusiness/models/inputRow';
import { searchSharp } from 'ionicons/icons';

export const THREAD_FILTER_FORM = (
  isFactory,
  stores,
  onChangeStore,
  onChangeStatus,
): Array<InputRowModel> => [
  {
    items: [
      {
        label: 'SCREEN.MESSAGES.COLS.STATUS',
        input: INPUT.RADIO,
        gridSize: 8,
        options: insertEmptyOption(TICKET_STATUS_OPTIONS || [], 'All', ''),
        value: 'status',
        onChange: onChangeStatus,
      },
      ...(isFactory
        ? [
            {
              input: INPUT.AUTOCOMPLETE,
              label: 'SCREEN.MESSAGES.COLS.STORE',
              disableFastField: true,
              gridSize: 4,
              value: 'store',
              config: {
                optionProp: {
                  label: 'name',
                  value: 'id',
                },
                data: stores,
              },
              onChange: onChangeStore,
            },
          ]
        : []),
      {
        label: 'SCREEN.MESSAGES.COLS.TICKETNO',
        input: INPUT.TEXT,
        gridSize: 3,
        icon: searchSharp,
        enterToSubmit: true,
        value: 'ticketNumber',
      },
      {
        label: 'SCREEN.MESSAGES.COLS.TITLE',
        input: INPUT.TEXT,
        gridSize: 3,
        icon: searchSharp,
        enterToSubmit: true,
        value: 'title',
      },
      {
        label: 'SCREEN.MESSAGES.COLS.CONTENT',
        input: INPUT.TEXT,
        gridSize: 3,
        icon: searchSharp,
        enterToSubmit: true,
        value: 'content',
      },
      ...(isFactory
        ? [
            {
              label: 'SCREEN.MESSAGES.COLS.NAME',
              input: INPUT.TEXT,
              gridSize: 3,
              icon: searchSharp,
              enterToSubmit: true,
              value: 'contactName',
            },
          ]
        : []),
    ],
  },
];

export default THREAD_FILTER_FORM;

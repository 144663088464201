import React, { useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { IonContent, IonFooter } from '@ionic/react';

import { Flex, PageWrapper } from '$gstyles/wrapper';
import { FormSection } from '$gcomponents/reusables';
import intl from '$gintl';
import { Formik } from 'formik';
import { Header as FactoryHeader } from '$gcomponents/widgets';
import StoreHeader from '../../../components/header';
import { SPACE } from '$gstyles';
import { Button } from '$gcomponents/primitives';
import { input } from '$gbusiness/helpers';
import AttachmentForm from '$fcomponents/contactForm/attachmentForm';
import { threadActions } from '$fbusiness/redux/thread';
import { screen } from '$fcomponents/hoc';
import { IonPageWrapper } from './styles';
import NEW_MESSAGE_FORM from './newMessageForm';
import { fileActions } from '$fbusiness/redux/file';

interface ContactUsProps {
  history;
  currentState;
  stores;
}

const NewMessageScreen: React.FC<ContactUsProps> = ({ currentState, history, stores }) => {
  const dispatch = useDispatch();
  const [files, setFiles] = useState<Array<any>>([]);
  const [overMax, setOverMax] = useState(false);
  const MAX_FILES_SIZE = 5000000000;

  const formRef = useRef<any>();

  const initialValues = {
    title: '',
    body: '',
  };

  const newMessageForm = NEW_MESSAGE_FORM(stores, currentState.isFactory);

  const validateForm = (values) => {
    return input.validateError(newMessageForm, values);
  };

  const onSubmitEmail = async (values, formik) => {
    const { title, body, store, name, email, phone } = values;
    const param: any = {
      title,
      body,
      ...(store ? { storeId: store.id } : { name, email, phone }),
      isAdmin: currentState.isFactory ? 1 : 0,
      userId: currentState.user?.userId,
      status: 'NEW',
    };

    if (files.length) {
      const fileResult = await dispatch(fileActions.addFiles(files, 'message'));
      if (fileResult[0]) param.files = fileResult[0];
    }

    const result = await dispatch(threadActions.saveThread(param));

    if (result) {
      formik.resetForm();
      setFiles([]);
      history.goBack();
    }
  };

  const onChangeAttachment = (newFiles, isOver) => {
    setFiles(newFiles);
    setOverMax(isOver);
  };

  const Header = currentState.isFactory ? (
    <FactoryHeader title="SCREEN.MESSAGES.TITLE_NEW" />
  ) : (
    <StoreHeader title="SCREEN.MESSAGES.TITLE_NEW" />
  );

  return (
    <IonPageWrapper>
      {Header}
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={initialValues}
        validate={validateForm}
        onSubmit={(values, formik) => {
          onSubmitEmail(values, formik);
        }}>
        {(formik) => {
          const isFormValid = formik.isValid && !overMax;
          return (
            <>
              <IonContent>
                <PageWrapper maxWidth="600px">
                  <FormSection formik={formik} FORM={newMessageForm} marginBottom="0" />
                  <AttachmentForm maxSize={MAX_FILES_SIZE} files={files} onChange={onChangeAttachment} />
                </PageWrapper>
              </IonContent>
              <IonFooter>
                <Flex padding={SPACE.MEDIUM} justifyContent="center">
                  <Button className="half" disabled={!isFormValid} onClick={formik.handleSubmit}>
                    {intl('BUTTON.SUBMIT')}
                  </Button>
                </Flex>
              </IonFooter>
            </>
          );
        }}
      </Formik>
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  stores: state.store.stores,
  thread: state.thread.thread,
});

const mapDispatchToProps = {
  onDehydrate: threadActions.dehydrate,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(NewMessageScreen));

import _ from 'lodash';
import React, { useState } from 'react';
import { FastField, Field } from 'formik';

import intl from '$gintl';
import { BSLabel, Wrapper } from './styles';
import { configs } from '$configs';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  TextFieldProps,
} from '@mui/material';
import OutlinedDiv from './outlined';

interface MultiCheckProps {
  label?: string;
  labelText?: string;
  name: string;
  formik?: any;
  isMultiline?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  className?: string;
  placeholder?: string;
  position?: TextFieldProps['variant'];
  options: Array<any>;
  size?: string;
  selectAllText?: string;
  defaultValue?: string | number | null;
  handleChange?: Function;
  disableFastField?: boolean;
}

const defaultProps: MultiCheckProps = {
  label: '',
  labelText: '',
  name: '',
  formik: {},
  className: '',
  isMultiline: false,
  disabled: false,
  readonly: false,
  placeholder: '',
  position: undefined,
  options: [],
  selectAllText: '',
  size: configs.display.inputSize,
  defaultValue: null,
  disableFastField: false,
};

const MultiCheck: React.FC<MultiCheckProps> = ({
  label,
  labelText,
  name,
  formik,
  disabled,
  isMultiline,
  readonly,
  className,
  placeholder,
  position,
  options,
  size,
  selectAllText = '',
  defaultValue,
  handleChange,
  disableFastField,
}) => {
  const [selectAll, setSelectAll] = useState(false);
  const onCheck = (value, isChecked) => {
    const values = _.get(formik.values, name) || [];
    const newValue = isChecked ? [...values, value] : values.filter((v) => v !== value);
    formik.setFieldValue(name, newValue);
    if (handleChange) handleChange(null, newValue);
    setSelectAll(false);
  };
  const onSelectAll = (value) => {
    setSelectAll(value);
    if (value)
      formik.setFieldValue(
        name,
        options.map((o) => o.value),
      );
    else formik.setFieldValue(name, []);
  };
  const labelProps =
    position !== undefined
      ? {
          label: intl(label),
          InputLabelProps: {
            ...{ shrink: true },
          },
        }
      : {};
  const showSelectAll = selectAllText.length > 0 && options.length > 1;

  const variant = position || 'outlined';
  const inputSize = size === 'small' || size === 'xsmall' ? 'small' : 'medium';
  const FormField = disableFastField ? Field : FastField;

  const errorKey = _.get(formik.errors, name);
  const hasError = errorKey !== undefined;
  const errorMsg = hasError ? intl(`INPUT.ERROR.${errorKey}`) : undefined;
  const adjustedOptions = (
    <FormControlLabel
      className="select-all"
      control={<Checkbox checked={selectAll} onChange={(e) => onSelectAll(e.target.checked)} name={name} />}
      label={selectAllText}
      style={{ borderBottom: '1px solid #ccc' }}
    />
  );

  return (
    <Wrapper
      isMultiline={isMultiline}
      size={inputSize}
      className={`radio-group checkbox-group ${className} ${size === 'xsmall' ? 'xsmall' : size}`}>
      <FormField name={name}>
        {({ form }) => {
          const values = _.get(form.values, name) || [];
          const checkOptions = options.map((o, i) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.includes(o.value)}
                  onChange={(e) => onCheck(o.value, e.target.checked)}
                  name={name}
                />
              }
              label={o.labelText ? o.labelText : intl(`INPUT.OPTION.${o.label}`, null, true) || intl(o.label)}
              key={i}
            />
          ));
          return (
            <FormControl size={inputSize} variant={variant} error={hasError} fullWidth>
              {position === undefined && <BSLabel className="bs-label">{intl(label)}</BSLabel>}
              <OutlinedDiv labelProps={labelProps} variant={variant}>
                {showSelectAll && <FormGroup>{adjustedOptions}</FormGroup>}
                <FormGroup>{checkOptions}</FormGroup>
              </OutlinedDiv>
              <FormHelperText>{errorMsg}</FormHelperText>
            </FormControl>
          );
        }}
      </FormField>
    </Wrapper>
  );
};

MultiCheck.defaultProps = defaultProps;

export default MultiCheck;

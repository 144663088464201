import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { IonContent } from '@ionic/react';

import { screen } from '$gcomponents/hoc';
import { Header as FactoryHeader, TableView2 } from '$gcomponents/widgets';
import StoreHeader from '../../../components/header';

import { IonPageWrapper } from './styles';
import FabButton from '$fcomponents/fabButton';
import PATH from '$fbusiness/enums/paths';
import { MESSAGE_TABLE, MESSAGE_TABLE_CONFIG } from './messageTable';
import { Formik } from 'formik';
import { FormSection } from '$gcomponents/reusables';
import THREAD_FILTER_FORM from './threadFilterForm';
import { Button } from '$gcomponents/primitives';
import intl from '$gintl';

interface MessagesScreenProps {
  history;
  stores;
  onHydrate;
  onDehydrate;
  currentState;
}

const MessagesScreen: React.FC<MessagesScreenProps> = React.memo(
  ({ history, currentState, stores }) => {
    const formRef = useRef<any>();
    const [filter, setFilter] = useState<any>({});
    const { isFactory } = currentState;

    const initialValues = {
      content: '',
      title: '',
      contactName: '',
      ticketNumber: '',
      storeId: 0,
      status: '',
    };

    const onClickRow = (row) => {
      history.push(`${PATH.MESSAGE}/${row.id}`);
    };

    const Header = currentState.isFactory ? (
      <FactoryHeader title="SCREEN.MESSAGES.TITLE" />
    ) : (
      <StoreHeader title="SCREEN.MESSAGES.TITLE" />
    );

    const onChangeStatus = (status) => {};
    const onChangeStatusFilter = ({ value }) => {
      updateFilter({ status: value });
    };
    const onChangeStore = ({ value }) => {
      updateFilter({ storeId: value ? parseInt(value.id) : 0 });
    };
    const updateFilter = (newFilter) => {
      const formValues = formRef.current.values;
      const { store, ...rest } = formValues;
      setFilter({
        ...filter,
        ...rest,
        ...newFilter,
        forceRefresh: !filter.forceRefresh,
      });
    };

    const filterForm = THREAD_FILTER_FORM(isFactory, stores, onChangeStore, onChangeStatusFilter);

    const onSubmit = (values) => {
      updateFilter({});
    };

    return (
      <IonPageWrapper>
        {Header}
        <IonContent>
          <Formik
            innerRef={formRef}
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={(values) => {
              onSubmit(values);
            }}>
            {(formik) => (
              <TableView2
                filter={filter}
                tableConfig={MESSAGE_TABLE_CONFIG(onClickRow, isFactory)}
                TABLE={MESSAGE_TABLE(onChangeStatus, isFactory)}>
                <FormSection
                  className="serial-filter"
                  lastColumn={
                    <Button
                      color="secondary"
                      className="filter-submit"
                      size="small"
                      onClick={formik.handleSubmit}>
                      {intl('BUTTON.SUBMIT')}
                    </Button>
                  }
                  FORM={filterForm}
                  formik={formik}
                />
              </TableView2>
            )}
          </Formik>
        </IonContent>
        <FabButton route={PATH.NEW_MESSAGE} />
      </IonPageWrapper>
    );
  },
  (pp, np) => true,
);

const mapStateToProps = (state) => ({
  resetOnRoute: true,
  stores: state.store.stores,
});

const mapDispatchToProps = {};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(MessagesScreen));

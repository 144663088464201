import React from 'react';
import intl from '$intl';
import OrderModel from '$fbusiness/models/order';
import { Flex } from '$gstyles/wrapper';
import { ColAuto, Div, Row, SPACE } from '$gstyles';
import FactoryModel from '$fbusiness/models/factory';
import { currency, joinAddress, parseAddress, percentage } from '$gbusiness/helpers/util';
import {
  ORDER_DETAILS_TABLE,
  ORDER_DETAILS_TABLE_CONFIG,
  ORDER_DETAILS_TABLE_CONFIG_F,
} from './orderDetailsTable';
import { format } from '$gbusiness/helpers/date';
import { DATE_FORMATS } from '$gbusiness/enums';
import { Table } from '$gcomponents/reusables';

import OrderSummary from '../orderSummary';
import { Wrapper } from './styles';
import InvoiceModel, { calculatePriceFromInvoice } from '$fbusiness/models/invoice';
import { ORDER_STATUS } from '$fbusiness/enums/options/orderStatus';
import CurrentStateModel from '$fbusiness/models/currentState';
import InvoicePaymentSummary from '../orderSummary/invoicePaymentSummary';
import { getSalesman } from '$fbusiness/helpers/util';
import { stringToHtml } from '$gbusiness/helpers/input';
import { INVOICE_PAYMENTS_TABLE_MIN } from '$fscreens/accountScreen/invoicesScreen/innerTable';
import { INVOICES_TABLE_CONFIG } from '$fscreens/accountScreen/invoicesScreen/invoicesTable';
import { defaultTable, TableModel } from '$gbusiness/models/table';
import { TableView2 } from '$gcomponents/widgets';

interface OrderDetailsProps {
  factory: FactoryModel;
  invoice?: InvoiceModel;
  itemId?: number;
  currentState: CurrentStateModel;
  order?: OrderModel;
}

const OrderDetails: React.FC<OrderDetailsProps> = ({ currentState, order, itemId, invoice, factory }) => {
  // if (!order) return null;
  // const { orderId, storeName, deliveryAddress, note, status, deliveryDate } = order;

  const store = invoice?.store;
  const items = invoice ? invoice.items : order?.items || [];
  const { name: factoryName, formatted, phone: fromPhone, settings } = factory;
  const { hidePrice } = currentState;
  const fromAddress = parseAddress(formatted);
  const toAddress = parseAddress(order?.deliveryAddress) || store?.address;
  const price = calculatePriceFromInvoice(invoice || order);
  const hasShippingAddress =
    store?.shippingAddress?.formatted && store?.shippingAddress?.formatted !== store.address?.formatted;

  const isSalesman = !!settings?.salesmen && getSalesman(invoice);
  const invoicePayment = invoice
    ? {
        total: invoice.total,
        paidAmount: invoice.paidAmount,
        creditAmount: invoice.creditAmount,
        paymentDiscount: invoice.paymentDiscount,
        balance: invoice.balance,
      }
    : null;

  const isInvoice = !!invoice;
  const isFlagged = order?.status === ORDER_STATUS.FLAGGED;
  const isRegenerated = !!invoice?.revision;

  const showVendor = factory.settings?.showVendor || false;

  const itemsTable = ORDER_DETAILS_TABLE({ isInvoice, isFlagged, isRegenerated, hidePrice, showVendor });
  const tableProps = {
    styles: ORDER_DETAILS_TABLE_CONFIG_F(itemId).styles,
    TABLE: itemsTable,
    data: items,
    blankText: ORDER_DETAILS_TABLE_CONFIG.blankText,
  };

  const tempConfig = INVOICES_TABLE_CONFIG.innerConfig || defaultTable;

  const paymentTableConfig: TableModel = {
    ...tempConfig,
    styles: {
      ...tempConfig.styles,
      minWidth: 800,
      maxWidth: 1000,
      fontSize: '0.9em',
      cellPadding: '0px 2px',
      isStripped: false,
      rowHeight: 30,
      headerHeight: 24,
    },
  };

  const isPickup = factory.settings?.pickupOnly ? true : false;
  const defaultDiscount = (store?.discounts || []).find((d) => d.id === store?.settings?.defaultDiscount);
  return (
    <Wrapper hasShipping={hasShippingAddress}>
      <div className="invoice-mode"></div>
      <Flex alignItems="flex-start" className="invoice-print invoice-info" marginTop={SPACE.XLARGE}>
        <Row className="row invoice-info">
          <ColAuto className="fromto from col">
            <div className="title">{factoryName}</div>
            <div className="info">
              <div>{fromAddress?.street}</div>
              <div>{joinAddress(fromAddress)}</div>
              <div>{fromPhone}</div>
              {stringToHtml(settings?.invoiceData)}
            </div>
          </ColAuto>
          <ColAuto className="fromto to col">
            <div className="title">{intl('SCREEN.ORDER.SHIP_TO')}:</div>
            <div className="info">
              {!hasShippingAddress && <div>{order?.storeName || store?.name}</div>}
              {hasShippingAddress && <div className="semi-bold">{intl('SCREEN.INVOICES.COLS.BILL_TO')}</div>}
              <div>{toAddress?.street}</div>
              <div>{joinAddress(toAddress)}</div>
              {defaultDiscount && (
                <div>
                  DC Rate:{' '}
                  {defaultDiscount.isFlat
                    ? currency(defaultDiscount.amount)
                    : percentage(defaultDiscount.amount)}
                </div>
              )}
            </div>
          </ColAuto>
          {hasShippingAddress && (
            <ColAuto className="fromto to col">
              <div className="title">{order?.storeName || store?.name}</div>
              <div className="info">
                <div className="semi-bold">{intl('SCREEN.INVOICES.COLS.SHIP_CITY')}</div>
                <div>{toAddress?.street}</div>
                <div>{joinAddress(toAddress)}</div>
              </div>
            </ColAuto>
          )}

          <ColAuto className="order-section">
            <Div width="100%">
              {order && (
                <>
                  <div className="line">
                    <div className="label">{intl('SCREEN.ORDER.ORDERNO')}</div>
                    <div className="value">{order.orderNumber}</div>
                  </div>
                  <div className="line">
                    <div className="label">{intl('SCREEN.ORDER.DATE')}</div>
                    <div className="value">{format(order.createdAt, DATE_FORMATS.SLASH)}</div>
                  </div>
                  <div className="line">
                    <div className="label">
                      {intl('SCREEN.ORDER.' + (isPickup ? 'PICKUP_DATE' : 'DELIVERY_DATE'))}
                    </div>
                    <div className="value">{format(order.deliveryDate, DATE_FORMATS.SLASH)}</div>
                  </div>
                </>
              )}
              {invoice?.author && (
                <div className="line">
                  <div className="label">{intl('SCREEN.INVOICES.COLS.AUTHOR')}</div>
                  <div className="value">{invoice.author?.name || ''}</div>
                </div>
              )}
              {invoice?.confirmedAt && (
                <div className="line">
                  <div className="label">
                    {intl('SCREEN.ORDER.' + (isPickup ? 'PICKEDUP_DATE' : 'DELIVERED_DATE'))}
                  </div>
                  <div className="value">{format(invoice?.confirmedAt, DATE_FORMATS.SLASH)}</div>
                </div>
              )}
              {invoice?.term && (
                <div className="line">
                  <div className="label">{intl('SCREEN.INVOICES.COLS.TERM')}</div>
                  <div className="value">{invoice?.term?.name || ''}</div>
                </div>
              )}
              {isSalesman && (
                <div className="line">
                  <div className="label">{intl('SCREEN.ORDER.SALESPERSON')}</div>
                  <div className="value">{getSalesman(invoice)}</div>
                </div>
              )}
            </Div>
          </ColAuto>
        </Row>
      </Flex>
      {invoice && invoice?.noteFactory && (
        <div className="note-wrapper">
          <div>{invoice?.noteFactory}</div>
        </div>
      )}

      <Div marginTop={SPACE.XLARGE} className="table-wrapper">
        <Table {...tableProps} />
      </Div>

      <Flex className="invoice-print">
        <div className="note-wrapper">
          <>{order && order?.note && !invoice?.noteFactory && <div>{order?.note}</div>}</>
        </div>
        <div className="summary-wrapper">
          <br />
          <OrderSummary
            isCharge={!!invoice?.isCharge}
            price={invoice ? price : { ...price, refund: order?.refund }}
            isHidden={hidePrice}
          />
        </div>
      </Flex>
      {isFlagged && invoice?.noteStore && (
        <div className="flag-message no-print">
          <div>{intl('SCREEN.INVOICES.NOTE_FROM_STORE')}</div>
          <div className="error">{invoice?.noteStore}</div>
        </div>
      )}
      {invoice && !hidePrice && invoice.payments?.length > 0 && (
        <>
          <Flex className="invoice-print" justifyContent="flex-end">
            <div className="summary-wrapper">
              <InvoicePaymentSummary invoicePayment={invoicePayment} />
            </div>
          </Flex>
          <TableView2
            header={<h4>Payments & Credits</h4>}
            data={invoice?.payments}
            TABLE={INVOICE_PAYMENTS_TABLE_MIN()}
            tableConfig={paymentTableConfig}
          />
        </>
      )}
      {settings?.invoiceTerms && !invoice?.isCharge && (
        <div className="terms">{stringToHtml(settings?.invoiceTerms)}</div>
      )}
    </Wrapper>
  );
};

export default OrderDetails;

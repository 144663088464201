import React from 'react';
import intl from '$intl';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { authActions } from '$gbusiness/redux/auth';
import { useHistory } from 'react-router';
import PATH from '$fbusiness/enums/paths';

interface AccountMenuProps {
  isLoggedIn: boolean;
}

const AccountMenu: React.FC<AccountMenuProps> = ({ isLoggedIn }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const nav = (path) => {
    history.push(path);
  };
  return (
    <div className="account-menu" style={{ width: '200px' }}>
      <Button className="left" fullWidth onClick={() => nav(PATH.ACCOUNT)}>
        {intl('SCREEN.ACCOUNT.TITLE')}
      </Button>
      <Button className="left" fullWidth onClick={() => nav(PATH.HISTORY)}>
        {intl('SCREEN.ACCOUNT.HISTORY')}
      </Button>
      <Button className="left" fullWidth onClick={() => nav(PATH.PROFILE)}>
        {intl('SCREEN.ACCOUNT.PROFILE')}
      </Button>
      <Button className="left" fullWidth onClick={() => nav(PATH.CONTACT_US)}>
        {intl('SCREEN.CONTACT.TITLE')}
      </Button>
      <Button className="left" fullWidth onClick={() => dispatch(authActions.logout())}>
        {intl('BUTTON.LOGOUT')}
      </Button>
    </div>
  );
};

export default AccountMenu;
